.sidebar-tabs {
    overflow: hidden;
    flex-grow: 1;
    width: calc(100% - var(--ylv-panel-resize-handle-width));
    height: calc(100vh - var(--ylv-menu-bar-height) - var(--ylv-status-bar-height));
}

.sidebar-tab-list-spacing {
    flex-grow: 1;
}
