.drag-container {
  width: 100%;
  height: 100%;
}

.drag-wrapper {
  z-index: 998;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.drop-container {
  z-index: 999;
  position: absolute;
  border-radius: 1rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.upload-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.upload-container {
  width: 400px;
  height: 300px;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  margin-bottom: 1rem;
}

.upload-wrapper[data-theme="light"] {
  background: #fff;
  color: black;

  & .upload-container {
    background-color: #f5f2f2;
    border: dashed 1px #a0a0a0;
  }

  & .heading {
    color: black;
  }
}

.upload-wrapper[data-theme="dark"] {
  background: #343a40;
  color: white;

  & .upload-container {
    background-color: #222;
    border: dashed 1px #818181;
  }

  & .heading {
    color: white;
  }
}
