.menu-left {
  float: left;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.menu-right {
  float: right;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.menu-item {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 7px;
}

.menu-item-btn {
  border: 0;
  background: none;
}

.menu-divider {
  width: 1px;
  height: 100%;
  background: #494c50;
}

kbd {
  display: inline-block;
  border-radius: 4px;
  padding: 0.05em 0.25em;
  margin: 0 0.1em;
  font-size: 9px;
  background-color: #f7f7f7;
}

.viewer-header[data-theme="light"] {
  .menu-divider {
    background: #c4c8cd;
  }

  .page-input {
    border-color: red;
  }

  .menu-item-btn {
    color: #000;
  }
  .menu-item-btn:disabled {
    color: #ccc;
  }
  .menu-item-btn:hover:enabled {
    background: #cecece;
  }
}

.viewer-header[data-theme="dark"] {
  .menu-divider {
    background: #494c50;
  }

  .page-input {
    border-color: green;
  }

  .menu-item-btn {
    color: #ccc;
  }
  .menu-item-btn:disabled {
    color: #6e6e6e;
  }
  .menu-item-btn:hover:enabled {
    background: #454545;
  }
}

.modal-light {
  background-color: #fff !important;
  color: #000 !important;

  & .num-event-input {
    background-color: #fff !important;
    font-size: 14px;
    color: #000;
  }

  & .num-event-input:focus {
    color: #000;
  }

  & tr {
    color: #000;
  }

  & .modal-header {
    border-bottom: solid 0.5px #dee2e6;
  }

  & .modal-footer {
    border-top: solid 0.5px #dee2e6;
  }

  kbd {
    background: #565d64;
  }
}

.modal-dark {
  background-color: #333 !important;
  color: #fff !important;

  & .num-event-input {
    background-color: #0000 !important;
    font-size: 14px;
    color: #fff;
  }

  & .num-event-input:focus {
    color: #fff;
  }

  & tr {
    color: #fff;
  }

  & .modal-header {
    border-bottom: solid 0.5px #444;
  }

  & .modal-footer {
    border-top: solid 0.5px #444;
  }

  kbd {
    background: #000;
  }
}

.loading-progress-bar {
  height: 3px !important;
}
