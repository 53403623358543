.log-level-chip {
    /* stylelint-disable-next-line custom-property-pattern */
    --Chip-radius: 0;

    font-family: var(--ylv-ui-mono-font-family), monospace !important;
    font-weight: 600 !important;
}

.log-level-chip span {
    width: 1.4ch;
}
