.search-tab-container {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.query-results {
    overflow-y: auto;
    flex-grow: 1;
}
