.status-bar {
    position: absolute;
    z-index: var(--ylv-status-bar-z-index);
    bottom: 0;

    display: flex;
    flex-wrap: wrap;
    align-items: center;

    width: 100%;

    /* stylelint-disable-next-line custom-property-pattern */
    box-shadow: 0 -1px 0 0 var(--joy-palette-neutral-outlinedBorder);
}

.status-message {
    flex-grow: 1;
}
