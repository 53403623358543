.sidebar-tab-button {
    justify-content: center !important;
    width: 48px;
    height: 48px;
    padding: 0 !important;
}

.sidebar-tab-button-icon {
    font-size: 32px !important;
}
