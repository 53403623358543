.page-num-input {
    /* Ensures the content within the element does not wrap, preventing visual overflow when
      adjacent elements under the same parent container attempt to take up space. */
    white-space: nowrap;
}

/* Remove the spin buttons (up/down arrows) on number input fields for WebKit-based browsers.
   NOTE: This approach does not work for Firefox Browser. See the next rule for mitigation. */
.page-num-input input[type="number"]::-webkit-outer-spin-button,
.page-num-input input[type="number"]::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
}

/* Show number input fields as `textfield` to remove the spin buttons for Firefox Browser. */
.page-num-input input[type="number"] {
    appearance: textfield;
}

.page-num-input-num-pages-text {
    font-size: inherit !important;
}

.page-num-input-num-pages-text-disabled {
    /* stylelint-disable-next-line custom-property-pattern */
    color: var(--joy-palette-neutral-plainDisabledColor) !important;
}
