.editable-value {
  border-bottom: dotted 1px #787800;
  padding: 0 5px;
}

.editable-input {
  font-family: monospace;
  background: transparent;
  border: none;
  font-size: 14px;
  width: 60px;
  text-align: center;
}

.editable-input:focus {
  outline: none;
  border-bottom: dotted 1px #a1a2a3;
}
