.tab-details-header {
  display: flex;
  padding: 10px 0;
  align-items: center;
  border-bottom-color: lightgrey;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.tab-search-header-text {
  flex-grow: 1;
  font-size: 0.8rem;
  user-select: none;
}

.tab-search-header-button {
  border: none;
  background-color: transparent;
  &:disabled {
    pointer-events: none;
  }
}

.search-input {
  border: 1px solid;
  border-radius: 2px;
  height: calc(1.5rem + 6px);
  overflow-y: hidden;
  padding: 0 5px;
  resize: none;
  width: 100%;
}

.search-input-button {
  background-color: transparent;
  border: none;
}

.search-input:focus, .search-input:focus-visible {
  outline: 1px solid #0078d4;
}

.search-result-button {
  background-color: transparent;
  border: none;
  overflow: hidden;
  padding: 0 2px 0 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.search-result-button:hover, .search-result-button:focus-visible {
  outline: none;
}

.search-results-container {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding-left: 15px;
}

.search-results-container::-webkit-scrollbar {
  width: 9px;
}

.search-results-container::-webkit-scrollbar-track {
  background: transparent;
}

.search-results-page-header {
  background-color: transparent;
  text-align: left;
  padding: 0;
  border: none;
  width: 100%;
  display: flex;
}

.search-results-page-header-page-num {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.search-results-page-header-result-count {
  margin-right: 4px;
  border-radius: 5px;
  height: 1.4rem;
  min-width: 1.5rem;
  text-align: center;
}

$lightModeColor: #b4b4b4;
$lightModeBackgroundColor: #8c8c8c;
.viewer-container[data-theme="dark"] {
  .tab-search-header-button {
    color: $lightModeColor;
  }

  .tab-search-header-button:hover {
    background-color: $lightModeBackgroundColor;
  }

  .search-input {
    background-color: #2a2a2a;
    border-color: #444444;
    color: $lightModeColor;
  }

  .search-input-button {
    color: $lightModeColor;
  }

  .search-input-button:hover {
    background-color: $lightModeBackgroundColor;
  }

  .search-input-button-active {
    background-color: #245779;
  }

  .search-results-page-header-page-num {
    color: $lightModeColor;
  }

  .search-results-page-header-result-count {
    background: $lightModeBackgroundColor;
    color: #1e1e1e;
  }

  .search-result-button {
    color: $lightModeColor;
  }

  .search-result-button:hover, .search-result-button:focus-visible {
    color: #d6d6d6;
  }

  .search-results-container {
    scrollbar-color: #292929 transparent;
  }

  .search-results-container::-webkit-scrollbar-thumb {
    background-color: #292929;
  }

  .search-results-container::-webkit-scrollbar-thumb:vertical:hover {
    background-color: #303030;
  }

  .search-result-highlight {
    background-color: #603A23;
  }
}

$lightModeScrollBarColor: #d8d8d8;
$lightModeColor: #3b3b3b;
$lightModeBackgroundColor: #dcdcdc;
.viewer-container[data-theme="light"] {
  .tab-search-header-button {
    color: $lightModeColor;
  }

  .tab-search-header-button:hover {
    background-color: $lightModeBackgroundColor;
  }

  .search-input {
    background-color: #fff;
    border-color: #cecece;
    color: $lightModeColor;
  }

  .search-input-button {
    color: $lightModeColor;
  }

  .search-input-button:hover {
    background-color: $lightModeBackgroundColor;
  }

  .search-input-button-active {
    background-color: #d9e9fb;
  }

  .search-results-page-header-page-num {
    color: $lightModeColor;
  }

  .search-results-page-header-result-count {
    background: $lightModeBackgroundColor;
    color: #1e1e1e;
  }

  .search-result-button {
    color: $lightModeColor;
  }

  .search-result-button {
    color: #737373;
  }

  .search-result-button:hover, .search-result-button:focus-visible {
    color: #1f1f1f;
  }

  .search-results-container {
    scrollbar-color: $lightModeScrollBarColor transparent;
  }

  .search-results-container::-webkit-scrollbar-thumb {
    background-color: $lightModeScrollBarColor;
  }

  .search-results-container::-webkit-scrollbar-thumb:vertical:hover {
    background-color: #a8a8a8;
  }

  .search-result-highlight {
    background-color: #E3BDA6;
  }
}
