.verbosity-menu-container {
  background: #68217a;
  position: absolute;
  z-index: 10;
}

.verbosity-menu-container option {
  cursor: pointer;
}

.verbosity-menu-container option:hover {
  background: #34103d;
}
