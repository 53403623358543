// TODO Decide if we should hide this message
// Hides the monaco message which says:
// "cannot edit in read only mode"
// .monaco-editor-overlaymessage {
//  display: none !important;
// }

.monaco-container {
    width: 100%;
    height: 100%;
}