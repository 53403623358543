.sidebar-tab-panel {
    min-width: 0 !important;
    padding: 0.75rem !important;
    padding-right: 0.5rem !important;
}

.sidebar-tab-panel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sidebar-tab-panel-title-container {
    user-select: none;
    margin-bottom: 0.5rem !important;
}

.sidebar-tab-panel-title {
    flex-grow: 1;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    text-transform: uppercase;
}
