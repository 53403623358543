html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
}

html {
    font-size: 16px;
}

:root {
    /* font-family globals */
    --ylv-ui-font-family: -apple-system, "BlinkMacSystemFont", system-ui, "Ubuntu", "Droid Sans",
        "Inter";
    --ylv-ui-mono-font-family: "SF Mono", monaco, menlo, "Ubuntu Mono", "Liberation Mono",
        "DejaVu Sans Mono", "Roboto Mono";

    /* size globals */
    --ylv-status-bar-height: 32px;
    --ylv-menu-bar-height: 32px;
    --ylv-panel-resize-handle-width: 4px;

    /* z-index globals
     *
     * Other z-index values in the project for reference:
     * ```
     * .monaco-editor .minimap { z-index: 5; }
     * ```
     */
    --ylv-resize-handle-z-index: 1;
    --ylv-menu-bar-z-index: 6;
    --ylv-status-bar-z-index: 6;
    --ylv-loading-progress-z-index: 9;
    --ylv-drop-file-container-hover-mask-z-index: 10;
    --ylv-drop-file-container-hover-message-z-index: 11;
}

.disable-pointer-events {
    pointer-events: none !important;
}
