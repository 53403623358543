.viewer-header {
  font-size: 0.9rem;
  margin: 0;
}

.viewer-header-menu-container {
  height: 30px;
  width: 100%;
}

.viewer-container {
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.viewer-loading-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-container {
  width: 500px;
  height: 200px;
  display: flex;
  padding: 1.2rem;
  text-align: start;
  border-radius: 0.5rem;
  background-color: #222;
  color: #000;
}

.viewer-container[data-theme="light"] {
  background: #fff;

  .viewer-header {
    background-color: #f5f2f2;
    color: #000;
  }

  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem auto;
    width: 40rem;
  }

  .modal-background {
    background-color: #f5f2f2;
  }

  .loading-container {
    background-color: #f5f2f2;
    color: #000;
  }

  input {
    color: #000;
  }
}

.viewer-container[data-theme="dark"] {
  background: #1e1e1e;

  .viewer-header {
    background: #212529;
    color: #ccc;
  }

  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem auto;
    width: 40rem;
  }

  .modal-background {
    background-color: #212529;
  }

  .loading-container {
    background-color: #343a40;
    color: #fff;
  }

  input {
    color: white;
  }
}
